import React from 'react';

const TextArea = ({ name, placeholder, value, onChange, onBlur }) => (
  <textarea
    name={name}
    id={name}
    placeholder={placeholder}
    value={value}
    onChange={e => onChange?.(e)}
    onBlur={e => onBlur?.(e)}
    className={
      'outline-none text-gray-500 h-32 w-full p-2 border-2 border-gray-400 bg-transparent placeholder:capitalize'
    }
  />
);

export default TextArea;
