import React from 'react';
import classNames from 'classnames';

const Input = ({
  name,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  disabled,
  error,
  ...rest
}) => (
  <div className='flex flex-col'>
    <input
      name={name}
      id={name}
      type={type || 'text'}
      placeholder={placeholder}
      onChange={e => onChange?.(e)}
      onBlur={e => onBlur?.(e)}
      value={value}
      disabled={disabled}
      className={classNames(
        'text-gray-500 outline-none py-2 border-b-2 bg-transparent peer placeholder:capitalize',
        error ? 'border-red-300' : 'border-gray-400'
      )}
      {...rest}
    />

    <label className='order-first capitalize text-gray-700 transition-all peer-placeholder-shown:invisible peer-placeholder-shown:opacity-0 peer-placeholder-shown:translate-y-9'>
      {placeholder}
    </label>

    <div className='text-xs text-red-400 mt-1 min-h-[1rem]'>{error}</div>
  </div>
);

export default Input;
