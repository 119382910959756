import React from "react";
import Input from "../ui/input";
import TextArea from "../ui/textArea";

export default function Contact() {
    return (
        <>
  <section className="bg-gray-100 pt-40">
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
        <div className="lg:col-span-2 lg:py-12">
          <p className="max-w-xl text-lg">
            Thecro.org website is a dedicated consumer complaint portal for the members of the organization. We welcome your questions and comments regarding the complaint website. If you have any questions/queries call or email us.
          </p>
          <div className="mt-8">
            <a href="" className="text-2xl font-bold text-blue-500">
              +91 9977-252-252
            </a>
            <address className="mt-2 not-italic">
                info@thecro.org
            </address>
          </div>
        </div>
        <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
          <form action="" className="space-y-4">
             <Input
                type="text"
                name="name"
                placeholder="Your name"
              />
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label className="sr-only" htmlFor="email">
                  Email
                </label>
               <Input
                type="email"
                name="Email"
                placeholder="Your Email"
              />
              </div>
              <div>
                <label className="sr-only" htmlFor="phone">
                  Phone
                </label>
               <Input
                type="tel"
                name="phone"
                placeholder="Your Phone No"
              />
              </div>
            </div>
       
            <div>
              <label className="sr-only" htmlFor="message">
                Message
                                    </label>
                                    <TextArea
                                        
                                        rows={8}
                                        placeholder="message"
                                      
                                    />

            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
              >
                Send Enquiry
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</>

    );
}